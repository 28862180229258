/* eslint-disable */
/* eslint jsx-a11y/control-has-associated-label: 0 */

import "./styles/header.scss"

import * as styles from "./Header.module.scss"

import React, { useEffect, useState } from "react"

import AirgapBlue from "../images/Airgap_blue.svg"
import AirgapWhite from "../images/Airgap_white.svg"
import ArrowRight from "../images/link-rignt-ico-w.svg"
import { Container } from "react-bootstrap"
import Link from "../components/Link"
import MobileNav from "./MobileNav"
import { NavbarData } from "../../utils/StaticData"
import classnames from "classnames"
import { navigate } from "gatsby"
import orangeArrowRight from "../images/orange-righr-arrow.svg"
import { useHeaderBackground } from "../contexts/HeaderBackroundContext"
import useScrollPos from "../hooks/useScrollPos"

const Header = ({ pathname, background, colorOverride, imgOverride }) => {
  const [navOpen, setNavOpen] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const { value: backgroundColor } = useHeaderBackground()
  const [navData, setNavData] = useState(NavbarData)

  const toggleNav = () => setNavOpen(!navOpen)

  const navigateTo = to => {
    navigate(to)
    setNavOpen(false)
  }

  useScrollPos(offset => {
    setScrolled(offset > 50)
  }, [])

  useEffect(() => {
    setNavData(NavbarData)
  }, [])

  return (
    <>
      <header
        style={{ background }}
        id="header"
        className={classnames(styles.header, {
          [styles.headerScrolled]: isScrolled,
          [styles[`background_${backgroundColor}`]]:
            !isScrolled && backgroundColor,
          background: isScrolled ? "#ffffff" : "transparent",
        })}
      >
        <div
          className="top_wrapper"
          style={{
            boxShadow: isScrolled ? "0px 4px 25px rgba(0, 0, 0, 0.1)" : "none",
            paddingTop: isScrolled ? "24px" : "0",
            paddingBottom: isScrolled ? "24px" : "0",
          }}
        >
          <Container fluid="xl" className={!isScrolled && "nav-spacing"}>
            <div className={styles.headerRow}>
              <div className={styles.headerLogo}>
                <Link to="/" wrapper>
                  <img
                    className={styles.logo}
                    src={
                      isScrolled || pathname.includes("/forms")
                        ? imgOverride
                          ? imgOverride
                          : AirgapBlue
                        : AirgapWhite
                    }
                    alt="logo"
                  />
                </Link>
              </div>
              <div className={classnames(styles.burgerContainer, "d-lg-none")}>
                <span
                  className={`contact_us_btn ${
                    isScrolled || pathname.includes("/forms")
                      ? "contact_scroll"
                      : "contact"
                  }`}
                  onClick={() =>
                    navigateTo("https://www.zscaler.com/company/contact")
                  }
                >
                  Contact Us
                </span>
                <div
                  tabIndex={0}
                  role="button"
                  aria-label="hamburger menu"
                  className={styles.toggle}
                  onKeyUp={event => event.key === "Enter" && toggleNav()}
                  onClick={toggleNav}
                />
              </div>
              <div className="d-none d-lg-block link-container">
                <div className="d-flex link_wrapper">
                  {navData.map((data, index) => (
                    <div
                      key={index}
                      className={`link_item`}
                      style={{
                        color:
                          isScrolled || pathname.includes("/forms")
                            ? colorOverride
                              ? colorOverride
                              : "#0C304B"
                            : "#f9f9f9",
                      }}
                    >
                      {data.pageName}

                      <div className="header_dropdown">
                        <div className="header_submenu">
                          <div className="header_submenu-wrap">
                            <h3
                              className="page_name"
                              onClick={() => navigateTo(data.link)}
                            >
                              {data.pageName === "Solutions"
                                ? "Zero Trust Enterprise"
                                : data.pageName}
                            </h3>
                            <div className="divider-border" />
                          </div>
                          {data?.dropdownData?.map((item, i) => (
                            <div className="page_link_wrapper" key={i}>
                              <p
                                onClick={() => navigateTo(item.link)}
                                className={`page_link_name ${
                                  item.name === "Network Experience"
                                    ? "nw_ai "
                                    : ""
                                }`}
                              >
                                {item.name}
                              </p>
                              {item.name === "Compliance" && (
                                <img
                                  src={orangeArrowRight}
                                  alt="orangeArrowRight"
                                  className="dropdown-arrow"
                                />
                              )}
                              {item.name === "Compliance" && (
                                <div className="dropdown2" key={i}>
                                  <div className="dropdown2_data">
                                    {item?.dropdownData2?.map((item2, ind) => (
                                      <p
                                        key={ind}
                                        onClick={() => navigateTo(item2.link)}
                                        className="page_link_name"
                                      >
                                        {item2.name}
                                      </p>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-none d-lg-block">
                <div className="nav_btns">
                  <Link
                    rel="stylesheet"
                    className={`nav-btn ${
                      isScrolled ? "contact_scroll" : "contact"
                    }`}
                    to="https://www.zscaler.com/company/contact"
                  >
                    Contact US
                  </Link>
                  <a
                    rel="stylesheet"
                    className="nav-btn get_started"
                    href="https://go.airgap.io/get-demo"
                  >
                    Book Demo
                    <img src={ArrowRight} alt="ArrowRight" />
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>
      <div
        className={classnames(styles.overlay, {
          [styles.overlayOpen]: navOpen,
        })}
      >
        <MobileNav
          toggleNav={toggleNav}
          navigateTo={navigateTo}
          navData={navData}
        />
      </div>
    </>
  )
}

export default Header
